// FIND SIMILAR
export const FIND_SIMILAR_GENERAL_SIMILARITY_THRESHOLD = 0.8;

// TIMEOUT (milliseconds * seconds * minutes)
export const ABCCORP_IDLE_TIMEOUT = 1000 * 60 * 15;

// UPLOADING BIG FILES CHUNK - EFS
export const chunkSize = 1048576 * 10; // its ~10MB

// CONTRACTS
export const defaultReviewStates = [
  {
    name: 'Under Review',
    label: 'Under Review',
    action: 'set',
    default: true,
  },
  {
    name: 'With Counterparty',
    label: 'With Counterparty',
    action: 'set',
    default: false,
  },
];

export const routeNamesForAnalytics = [
  'tst',
  'business_risk_lowcoverage',
  'business_norisk',
  'legal',
];

// QUESTION TYPE STRINGS
export const QUESTION_TYPE_STRINGS = {
  YES_NO: 'Yes/No',
  YES_NO_TEXT_EDITOR: 'Yes/No with Input',
  MULTI_SELECT: 'Custom Labels',
  GOVERNING_LAW: 'Governing Law',
  FORUM: 'Forum',
  TERM: 'Term',
  FILL_IN_THE_BLANK: 'Fill in the blank',
  LIST_EDIT: 'List Edit',
};

// REPORT NAMES
export const CONTEXT_REPORT_NAME = 'Context';
export const PRESENCE_REPORT_NAME = 'Presence';
export const EDIT_OPERATIONS_REPORT_NAME = 'Edit Operations';
export const ALIGNMENT_REPORT_NAME = 'Alignment';
export const USER_EDITS_REPORT_NAME = 'User Edits';
export const RISK_REPORT_NAME = 'Risk';
export const ISSUE_REPORT_NAME = 'Issue';
export const REPORT_NAMES = [
  ALIGNMENT_REPORT_NAME,
  PRESENCE_REPORT_NAME,
  CONTEXT_REPORT_NAME,
  EDIT_OPERATIONS_REPORT_NAME,
  USER_EDITS_REPORT_NAME,
  ISSUE_REPORT_NAME,
];

// REPORTING GRID GLOBALS
export const ROW_DEFAULT_HEIGHT = 110; // 22 per row

// REPORTING GRID COLUMNS
export const XS_DEFAULT_WIDTH = 120;
export const SM_DEFAULT_WIDTH = 180;
export const M_DEFAULT_WIDTH = 200;
export const L_DEFAULT_WIDTH = 240;
export const XL_DEFAULT_WIDTH = 350;
export const XXL_DEFAULT_WIDTH = 1000;

export const DATE_DEFAULT_WIDTH = 220;
export const SENTENCE_LABEL_DEFAULT_WIDTH = 250;
export const SENTENCE_DEFAULT_WIDTH = 600;

export const TSV_EXPORT_SEPARATOR = '\t';
export const CSV_EXPORT_SEPARATOR = ',';

// For Setting Page
export const contractTypes = [
  { name: 'nda', label: 'NDA' },
  { name: 'servicesagreement', label: 'Services Agreement' },
  { name: 'subcontract', label: 'Subcontract' },
  { name: 'DFAR', label: 'DFAR' },
];

export const contractTypesMap = contractTypes.reduce(
  (acc, { name, label }) => ({ ...acc, [name]: label }),
  {}
);

export const defaultContractAttributesForExport = [
  '_id',
  'model',
  'name',
  'custodyLabel',
  'counterparty',
  'originalFilename',
  'slots',
  'storageState',
  'uploadUser',
  'userSlots',
  'groups',
  'custom_fields',
];

// ONLY OFFICE
export const ONLYOFFICE_BB_LOGO =
  'https://bbcedit-web-assets.s3.amazonaws.com/logos/BlackBoilerOneLinelogo.png';

export const FXI_ALIGNMENT_MODE = {
  NONE: 0,
  CONTEXT_BEFORE: 1,
  CONTEXT_FOLLOWING: 2,
  FULL_CONTEXT: 3,
  TAIL: 4,
};

export const ALIGNMENT_MODE_ENUMS_BY_INDEX = [
  'None',
  'Context before',
  'Context after',
  'Full context',
  'Tail',
];

export const DEFAULT_ACCEPTED_FILE_TYPES = ['.docx', '.doc', '.pdf'];

export const TRAIN_TYPES = {
  EMPTY: '',
  POINT: 'point',
  LIST_EDIT: 'list_edit',
  FSI: 'FSI',
  FPI: 'FPI',
  FSD: 'FSD',
  FPD: 'FPD',
  FCD: 'FCD',
  NSGL: 'NSGL',
  NSF: 'NSF',
  NST: 'NST',
};

export const EDIT_TYPES = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  REVISE: 'revise',
};

export const EDIT_PREVIEW_TYPES = {
  NORMAL: 'normal',
  TAIL: 'tail',
  UNSUPPORTED: 'unsupported',
};

export const TEXT_TYPES = {
  SENTENCE: 'sentence',
  PARAGRAPH: 'paragraph',
};

export const RULE_STRENGTHS = [
  { name: 'Very Weak', value: 0 },
  { name: 'Weak', value: 1 },
  { name: 'Okay', value: 2 },
  { name: 'Strong', value: 3 },
  { name: 'Very Strong', value: 4 },
];

export const FIND_SIMILAR_DEFAULT_LIMIT = 5;

export const TRAIN_TYPES_EXTENDED = [
  { name: 'No Changes', value: '' },
  { name: 'Point Edit', value: 'point' },
  { name: 'Full Sentence Insert', value: 'FSI' },
  { name: 'Full Paragraph Insert', value: 'FPI' },
  { name: 'Full Sentence Delete', value: 'FSD' },
  { name: 'Full Paragraph Delete', value: 'FPD' },
  { name: 'List Edit', value: 'list_edit' },
  { name: 'Governing Law', value: 'NSGL' },
  { name: 'Forum', value: 'NSF' },
  { name: 'Term', value: 'NST' },
];

export const TEXT_EDITOR_SLOT_TEMPLATE_STRATEGY = {
  TRAINING: 'TRAINING',
  CLAUSE: 'CLAUSE',
};

export const FXI_POSITIONS = {
  BEFORE: 'before',
  AFTER: 'after',
  MIDDLE: 'middle',
  TAIL: 'tail',
};

export const TAIL_POSITIONS = {
  DOCUMENT: 'document',
  SECTION: 'section',
  PARAGRAPH: 'paragraph',
  SENTENCE: 'sentence',
  END: 'end',
};

export const PARAMETER_DEFAULTS = {
  contextThresholdMin: 0.8,
  contextThresholdMax: 1,
  contextThresholdDefault: 0.85,
  presenceThresholdMin: 0.8,
  presenceThresholdMax: 1,
  presenceThresholdDefault: 0.85,
  contextWindowMin: 0,
  contextWindowMax: 100,
  contextWindowDefault: 50,
};

export const SLIDER_OPTIONS = [
  { key: 0, value: 'Low', visible: true },
  { key: 1, value: '', visible: false },
  { key: 2, value: '', visible: false },
  { key: 3, value: '', visible: false },
  { key: 4, value: '', visible: false },
  { key: 5, value: '', visible: false },
  { key: 6, value: '', visible: false },
  { key: 7, value: '', visible: false },
  { key: 8, value: '', visible: false },
  { key: 9, value: '', visible: false },
  { key: 10, value: 'High', visible: true },
];

export const RULE_STRENGTHS_MAP = Object.assign(
  {},
  ...RULE_STRENGTHS.map((rs) => ({ [rs.value]: rs.name }))
);

export const TRAIN_TYPES_MAP = Object.assign(
  {},
  ...TRAIN_TYPES_EXTENDED.map((tt) => ({ [tt.value]: tt.name }))
);

export const MAX_RULE_STRENGTH = 4;

export const GEO_OPTIONS = {
  Country: {
    searchMode: 'local',
    filters: [
      {
        'feature code': ['PCLI'],
      },
    ],
  },
  'US State': {
    searchMode: 'local',
    filters: [
      {
        'country code': ['US'],
        'feature code': ['ADM1'],
      },
    ],
  },
  'US County': {
    searchMode: 'local',
    filters: [
      {
        'country code': ['US'],
        'feature code': ['ADM2'],
      },
    ],
    showInOnly: ['forum'],
  },
  'US City': {
    searchMode: 'remote',
    filters: [
      {
        'country code': ['US'],
        'feature code': ['ADM3', 'PPL', 'PPLA', 'PPLA2', 'PPLA3'],
      },
    ],
    showInOnly: ['forum'],
  },
  'Canadian Province': {
    searchMode: 'local',
    filters: [
      {
        'country code': ['CA'],
        'feature code': ['ADM1'],
      },
    ],
  },
};

export const TOOLS_GRID_FIELDS = [
  { field: 'training_id', width: '7%' },
  { field: 'file', label: 'Contract', groupable: true, width: '10%' },
  { field: 'contractid', label: 'Contract ID', groupable: true },
  { field: 'order', label: 'Order', editable: true },
  { field: 'contractname', label: 'Contract Name', groupable: true },
  { field: 'session', label: 'Edit Session', groupable: true, hidden: true },
  { field: 'runs_lifespans', label: 'Run Lifespan', groupable: false, hidden: true },
  {
    field: 'protected',
    label: 'Protected',
    input: 'select',
    options: ['true', 'false'],
    editable: true,
    hidden: true,
  },
  {
    field: 'multiround_edit',
    label: 'Multiround Edit',
    groupable: true,
    input: 'select',
    width: '7%',
    options: ['true', 'false'],
    editable: true,
    hidden: true,
  },
  {
    field: 'protection_scope',
    label: 'Protection Scope',
    input: 'select',
    options: ['sent', 'para'],
    editable: true,
    hidden: true,
  },
  {
    field: 'protection_span',
    label: 'Protection Span',
    groupable: false,
    editable: true,
    hidden: true,
  },
  {
    field: 'protection_comment',
    label: 'Protection Comment',
    groupable: true,
    input: 'textarea',
    editable: true,
    hidden: true,
  },
  { field: 'sentence', editable: true, input: 'textarea', type: 'html', width: '27%' },
  {
    field: 'edited_sentence',
    editable: true,
    input: 'textarea',
    type: 'html',
    diff: 'sentence',
    width: '27%',
  },
  {
    field: 'edit_type',
    editable: true,
    input: 'select',
    width: '7%',
    options: ['accept', 'reject', 'revise', 'noop', 'comment'],
  },
  { field: 'train_type', editable: true, width: '6%', groupable: true },
  { field: 'list_type', editable: true, width: '6%' },
  { field: 'fsi_clusterid', editable: true, width: '5%', groupable: true },
  { field: 'fpi_clusterid', editable: true, width: '5%', groupable: true },
  { field: 'chunk_id', hidden: true },
  {
    field: 'is_nano_slot_edit',
    label: 'Nano Slot Edit',
    input: 'select',
    options: ['true', 'false'],
    editable: true,
    hidden: true,
  },
  {
    field: 'nano_slot_allowable_sections',
    label: 'Nano Slot Alloweable Sections(JSON)',
    editable: true,
    hidden: true,
  },
  {
    field: 'nano_slot_values',
    label: 'Nano Slot Values(JSON)',
    editable: true,
    hidden: true,
  },
  { field: 'alignment_mode', label: 'Alignment Mode', editable: true, hidden: true },
  { field: 'tail_type', label: 'Tail Type', editable: true, hidden: true },
  {
    field: 'tail_required',
    label: 'Tail Required',
    editable: true,
    input: 'select',
    width: '7%',
    options: ['True', 'False'],
    hidden: true,
  },
  {
    field: 'tail_fallback',
    label: 'Tail Fallback',
    editable: true,
    input: 'select',
    width: '7%',
    options: ['document', 'end'],
    hidden: true,
  },
  { field: 'docx_comment', editable: true, input: 'textarea', hidden: true },
  {
    field: 'comment_level',
    label: 'Comment Level',
    editable: true,
    input: 'select',
    options: ['match', 'sentence', 'paragraph'],
    hidden: true,
  },
  {
    field: 'pattern',
    label: 'Pattern',
    editable: true,
    input: 'textarea',
    hidden: true,
  },
  { field: 'flags', label: 'Flags', editable: true, input: 'textarea', hidden: true },
  {
    field: 'comment_merge',
    label: 'Merge Comments',
    editable: true,
    input: 'select',
    options: ['true', 'false'],
    hidden: true,
  },
  {
    field: 'section',
    label: 'Comment Section(s)',
    editable: true,
    input: 'textarea',
    hidden: true,
  },
  {
    field: 'section_threshold',
    label: 'Comment Section Threshold',
    editable: true,
    input: 'textarea',
    hidden: true,
  },
  {
    field: 'context_before',
    editable: true,
    hidden: true,
    type: 'html',
    input: 'textarea',
    width: '27%',
  },
  { field: 'context_before_section_label', hidden: true },
  {
    field: 'context_following',
    editable: true,
    hidden: true,
    type: 'html',
    input: 'textarea',
    width: '27%',
  },
  { field: 'context_following_section_label', editable: true, hidden: true },
  { field: 'context_size', editable: true, hidden: true },
  { field: 'presence_threshold', editable: true, hidden: true },
  { field: 'context_threshold', editable: true, hidden: true },
  { field: 'contno', hidden: true },
  { field: 'coord_compact', hidden: true },
  { field: 'date_added', type: 'date', hidden: true },
  { field: 'date_modified', type: 'date', hidden: true },
  { field: 'diff', hidden: true },
  { field: 'doc_id', hidden: true, groupable: true },
  { field: 'edit_authors', hidden: true },
  { field: 'edit_dates', hidden: true },
  { field: 'edited_sentence_section_label', hidden: true },
  {
    field: 'edited_sentence_slotted',
    editable: true,
    hidden: true,
    type: 'html',
    input: 'textarea',
  },
  { field: 'fco', hidden: true },
  { field: 'issue', label: 'Issue', editable: true, hidden: true, groupable: true },
  {
    field: 'issue_description',
    label: 'Issue Description',
    hidden: true,
    editable: true,
  },
  { field: 'list_edits', hidden: true },
  { field: 'model', hidden: true, groupable: true },
  { field: 'new_token_count', hidden: true },
  { field: 'note', hidden: true },
  { field: 'OBR10', hidden: true },
  { field: 'OBR11a', hidden: true },
  { field: 'OBR12', hidden: true },
  { field: 'OBR7aV2_date', hidden: true },
  { field: 'OBR7a_date', hidden: true },
  { field: 'OBR7bV2_adrs', hidden: true },
  { field: 'OBR7b_adrs', hidden: true },
  { field: 'OBR7c_orgs', hidden: true },
  { field: 'OBR7d', hidden: true },
  { field: 'OBR8', hidden: true },
  { field: 'OBR9', hidden: true },
  { field: 'old_token_count', hidden: true },
  { field: 'owner', hidden: true },
  { field: 'para_id', hidden: true },
  { field: 'rel_paragraph', hidden: true },
  { field: 'rel_sentence', hidden: true },
  { field: 'rel_sentinpara', hidden: true },
  { field: 'reviewed', hidden: false, editable: true, groupable: true },
  { field: 'sent_id', hidden: true },
  { field: 'sentence_section_label', hidden: true },
  { field: 'sentno', hidden: true },
  { field: 'user_added', hidden: true, groupable: true },
  { field: 'user_modified', hidden: true },
  { field: 'ver', hidden: true },
];
