import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager, ModuleRegistry, AllEnterpriseModule } from 'ag-grid-enterprise';

import { Box } from '@mui/material';

import { AG_ENTERPRISE_KEY, COLUMN_TYPES } from '../AGTable/AGTable.utils';

LicenseManager.setLicenseKey(AG_ENTERPRISE_KEY);
ModuleRegistry.registerModules([AllEnterpriseModule]);

const AGServerTable = ({
  sx,
  rowData,
  colDefs,
  rowHeight = 42,
  rowBuffer = 60,
  cacheSize = 500,
  cacheBlocks = 10,
  isSelectable = false,
  isMoveable = true,
  isFilterFloating = false,
  isSidebarVisible = false,
  isGroupingVisible = false,
  isAutoResize = false,
  pagination,
  defaultPagination = 50,
  contextMenuItems = [],
  onReady,
  onRowClick,
  onUpdate,
  onSelect,
  ...rest
}) => {
  // AG Grid needs the columns to be stored in state
  const [AGcolDefs, setAGcolDefs] = useState(colDefs);
  useEffect(() => {
    // Update stored column definitions
    if (colDefs) {
      setAGcolDefs(colDefs);
    }
  }, [colDefs]);

  // Default column settings
  const defaultColDef = useMemo(() => {
    return {
      type: 'string',
      minWidth: 72,
      suppressMovable: !isMoveable,
      floatingFilter: isFilterFloating,
      filterParams: {
        buttons: ['clear'],
      },
      contextMenuItems: [...contextMenuItems, 'copy', 'export'],
    };
  }, [contextMenuItems]);

  // Side bar settings
  const sideBarDef = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
          },
        },
      ],
      defaultToolPanel: 'columns',
    };
  }, []);

  // Row selection settings
  const rowSelection = useMemo(() => {
    return isSelectable
      ? {
          mode: 'multiRow',
          selectAll: 'filtered',
          enableClickSelection: false,
        }
      : { checkboxes: false, headerCheckbox: false, enableClickSelection: false };
  }, [isSelectable]);

  return (
    <Box
      sx={{
        height: '100%',
        '.ag-header-cell-text': { fontWeight: '700' },
        '.ag-root-wrapper': { borderRadius: '4px' },
        ...sx,
      }}
    >
      <AgGridReact
        rowModelType="serverSide"
        columnTypes={COLUMN_TYPES}
        columnDefs={AGcolDefs}
        pagination={Boolean(pagination?.length)}
        paginationPageSize={defaultPagination}
        paginationPageSizeSelector={pagination}
        sideBar={isSidebarVisible ? sideBarDef : {}}
        rowGroupPanelShow={isGroupingVisible ? 'always' : ''}
        autoSizeStrategy={isAutoResize ? { type: 'fitCellContents' } : undefined}
        cacheBlockSize={cacheSize}
        maxBlocksInCache={cacheBlocks}
        onGridReady={onReady}
        onRowClicked={(e) => onRowClick?.(e.rowIndex)}
        onModelUpdated={(e) => onUpdate?.(e.api)}
        onSelectionChanged={(e) => onSelect?.(e.api.getSelectedRows())}
        {...{ defaultColDef, rowHeight, rowBuffer, rowData, rowSelection }}
        {...rest}
      />
    </Box>
  );
};

AGServerTable.propTypes = {
  sx: PropTypes.object,
  rowData: PropTypes.any,
  colDefs: PropTypes.any,
  rowHeight: PropTypes.number,
  rowBuffer: PropTypes.number,
  cacheSize: PropTypes.number,
  cacheBlocks: PropTypes.number,
  isSelectable: PropTypes.bool,
  isSidebarVisible: PropTypes.bool,
  isGroupingVisible: PropTypes.bool,
  isAutoResize: PropTypes.bool,
  pagination: PropTypes.arrayOf(PropTypes.number),
  defaultPagination: PropTypes.number,
  contextMenuItems: PropTypes.any,
  serverDataSource: PropTypes.any,
  onReady: PropTypes.func,
  onRowClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onSelect: PropTypes.func,
};

export default AGServerTable;
