import React from 'react';
import moment from 'moment';

import { Typography, Checkbox, Chip } from '@mui/material';

import { Flex } from '../../components';

export const AG_ENTERPRISE_KEY =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-075302}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{BlackBoiler}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{BlackBoiler}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{BlackBoiler}_need_to_be_licensed___{BlackBoiler}_has_been_granted_a_Deployment_License_Add-on_for_{3}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{17_January_2026}____[v3]_[01]_MTc2ODYwODAwMDAwMA==546fa3facda108ec7f21e40165ca4d63';

export const AGCellWrapper = ({ children, sx = {} }) => {
  return <Flex.H sx={{ height: '100%', alignItems: 'center', ...sx }}>{children}</Flex.H>;
};

export const EMPTY_CELL = <AGCellWrapper>&mdash;</AGCellWrapper>;

export const AGTextCell = ({ children }) => {
  return (
    <AGCellWrapper>
      <Typography
        component={'span'}
        sx={{ userSelect: 'text', color: '#181d1f', fontSize: '14px', padding: '11px 0' }}
      >
        {children}
      </Typography>
    </AGCellWrapper>
  );
};

export const COLUMN_TYPES = {
  boolean: {
    cellRenderer: ({ value }) => (
      <AGCellWrapper>
        <Checkbox disabled checked={value} />
      </AGCellWrapper>
    ),
  },
  'boolean-to-string': {
    cellRenderer: ({ value }) => <AGTextCell>{value ? 'True' : 'False'}</AGTextCell>,
  },
  string: {
    cellRenderer: ({ value }) =>
      value || ['0', 0].includes(value) ? <AGTextCell>{value}</AGTextCell> : EMPTY_CELL,
  },
  date: {
    cellRenderer: ({ value }) =>
      value ? (
        <AGTextCell>{moment(value).format('MMM DD, YYYY h:mm a')}</AGTextCell>
      ) : (
        EMPTY_CELL
      ),
  },
  'short-date': {
    cellRenderer: ({ value }) =>
      value ? (
        <AGTextCell>{moment(value).format('MMM DD, YYYY')}</AGTextCell>
      ) : (
        EMPTY_CELL
      ),
  },
  percent: {
    cellRenderer: ({ value }) =>
      value ? <AGTextCell>{`${Math.round(value * 100)}%`}</AGTextCell> : EMPTY_CELL,
  },
  chip: {
    cellRenderer: ({ value }) =>
      value ? (
        <AGTextCell>
          <Chip label={value} />
        </AGTextCell>
      ) : (
        EMPTY_CELL
      ),
  },
  array: {
    cellRenderer: ({ value }) =>
      value && value.length ? (
        <AGTextCell>
          {value
            .map(({ name }) => name)
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}
        </AGTextCell>
      ) : (
        EMPTY_CELL
      ),
  },
  'object-to-string': {
    cellRenderer: ({ value }) =>
      value ? <AGTextCell>{JSON.stringify(value)}</AGTextCell> : EMPTY_CELL,
  },
};

export const searchAGTable = (search, data, columns) => {
  return data.filter((row) =>
    // Search through the available columns for each row
    columns.reduce((result, column) => {
      let bodyText = `${row[column.field]}`.toLowerCase();

      if (['modifiedBy', 'uploadUser'].includes(column.field)) {
        bodyText = `${row[column.field]?.email}`.toLowerCase();
      } else if (
        ['as_receiving_party', 'as_receiving_party_you'].includes(column.field)
      ) {
        bodyText = `${row.clause}`.toLowerCase();
      }

      return result || bodyText.indexOf(search.toLowerCase()) !== -1;
    }, false)
  );
};

export const compareAGDates = (filterDate, cellDate) => {
  const fDate = moment(filterDate);
  const cDate = moment(cellDate);

  if (cDate.format('YYYY-MM-DD') === fDate.format('YYYY-MM-DD')) {
    return 0;
  }
  if (cDate.isBefore(fDate)) {
    return -1;
  }
  if (cDate.isAfter(fDate)) {
    return 1;
  }
  return 0;
};

export const matchAGArrays = (filterOption, filterText, data) => {
  const formattedText = data
    .map(({ name }) => name)
    .join(' ')
    .toLowerCase();

  if (filterOption === 'notContains') {
    return !formattedText.includes(filterText.toLowerCase());
  }
  return formattedText.includes(filterText.toLowerCase());
};

export const matchAGText = (filterOption, filterText, data) => {
  const formattedText = data.toLowerCase();

  if (filterOption === 'notContains') {
    return !formattedText.includes(filterText.toLowerCase());
  }
  return formattedText.includes(filterText.toLowerCase());
};
