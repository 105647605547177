import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setErrMsg } from '../../redux/actions/snackbarActions';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import * as integrationsRequests from '../../requests/integrations';
import { BaseDialogPage } from '../../components';

const OnboardingIntegration = () => {
  const [webHost, setWebHost] = useState(null);

  const { getAuthHeader } = useAuthUpdateContext();
  const dispatch = useDispatch();

  const setErr = (value) => dispatch(setErrMsg(value));

  async function startAuth() {
    const headers = await getAuthHeader();
    try {
      const { data: authResp } = await integrationsRequests.authorizeUser(
        headers,
        { id: 'lawvu', webHost },
        true,
        true
      );
      const { authUri } = authResp;

      if (authUri) {
        console.debug(`Third party auth for integration navigating to ${authUri}`);
        window.location.href = authUri;
      } else {
        throw new Error(`Destination URI not present in response`);
      }
      return authResp;
    } catch (err) {
      console.error(err);
      setErr('Failed to authorize');
    }
  }

  async function loadIntegration() {
    const headers = await getAuthHeader();
    const { data: integration } = await integrationsRequests.getIntegration(
      headers,
      'lawvu'
    );

    if (!integration) {
      return null;
    }

    setWebHost(integration.fields.webHost);
  }

  useEffect(() => {
    loadIntegration();
  }, []);

  return (
    <BaseDialogPage
      extendedTitle="Click authorize to setup your LawVu integration now"
      rButtonText="Authorize"
      rButtonOnClick={() => startAuth()}
      showLButton={false}
      showTopNav={false}
    />
  );
};

export default OnboardingIntegration;
