// Convert camelCase and snake_case variable to Title
// thisName => This Name, this_name => This Name
export const convertToTitle = (str) => {
  if (str.includes('_')) {
    return str
      .split('_')
      .map((i) => i.charAt(0).toUpperCase() + i.slice(1))
      .join(' ');
  }

  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getSimModelName = (path) => {
  if (path) {
    const pathSplit = path.split('/');
    return pathSplit[pathSplit.length - 1].split('.')[0];
  }
  return path;
};

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const getAnalyticRouteName = (name) => {
  switch (name) {
    case 'business_norisk':
      return 'No Risk';
    case 'business_risk_lowcoverage':
      return 'Low Coverage';
    case 'legal':
      return 'Risk';
    case 'tst':
      return 'Test';
    default:
      console.log(`Failed get analytic route name with ${name}`);
      return '';
  }
};

export const convertToValidKeyForDomain = (domain) => domain.split('.').join('_');

export const revertKeyForDomain = (domain) => domain.split('_').join('.');

export const titleCase = (str) => {
  str = str || '';
  const wordParts = str.toLowerCase().split(' ');
  const newWords = [];
  for (const w of wordParts) {
    newWords.push(w.charAt(0).toUpperCase() + w.substring(1));
  }
  return newWords.join(' ');
};

export const humanizeTitle = (str) => {
  const parts = str.split(/[\s-_]+/);
  return titleCase(parts.join(' '));
};

/**
 * Converts text from camelCase to url-case.
 * @param {string} camelCase the text to update in camel case
 * @param {string} sep optional character for separator
 * @returns url-case string
 */
export function toUrlCase(camelCase, sep = '-') {
  return camelCase.replace(
    /[A-Z]/g,
    (match, offset) => `${offset > 0 ? sep : ''}${match.toLowerCase()}`
  );
}

/**
 * Converts objects to serializeable.
 * @param {object} obj JS object to serialize
 * @returns object with serialized values
 */
export function serializeParams(obj) {
  const serializedParams = Object.entries(obj).reduce((acc, [key, val]) => {
    if (typeof val === 'object' && !Array.isArray(val)) {
      acc[key] = JSON.stringify(obj[key]);
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
  return new URLSearchParams(serializedParams).toString();
}
