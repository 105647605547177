import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { Stepper, Step, StepLabel } from '@mui/material';

import * as integrationsRequests from '../../requests/integrations';
import { SETTINGS_ENDPOINT, MODELS_BY_QUESTIONNAIRE } from '../../endpoints.config';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { CreateModelFromInputs } from '../../redux/actions/modelActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import {
  GetQuestionnaires,
  GetQuestionnairesByContractType,
} from '../../requests/questionnaire';
import { capitalizeFirstLetter } from '../../utils/formatting';
import { Flex } from '../../components';
import OnboardingStepContent from './OnboardingStepContent';

const STEPPER_STEPS = [
  'Create Playbook',
  'Select Questions',
  'Answer Question Status',
  'Answer Questions',
];

const OnboardingAnswerQuestions = ({
  createdModelId = null,
  setIsChangedPreferredModel,
  setShowThankYou,
}) => {
  const { getAuthHeader, setConfig, config } = useAuthUpdateContext();

  const {
    setError,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    resetField,
  } = useForm({
    defaultValues: {
      name: '',
      contractType: '',
      templateType: '',
      defaultQuestionnaire: '',
    },
  });

  const [defaultQuestionnaires, setDefaultQuestionnaires] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [modelId, setModelId] = useState(createdModelId);
  const [contractTypes, setContractTypes] = useState([]);
  const [templateTypes, setTemplateTypes] = useState([]);
  const [filteredTemplateTypes, setFilteredTemplateTypes] = useState([]);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);

  const dispatch = useDispatch();

  const setErr = (value) => dispatch(setErrMsg(value));

  const topRef = useRef(null);

  const getQuestionnaires = (contractTypes) => {
    getAuthHeader().then((headers) => {
      GetQuestionnaires(headers)
        .then((data) => {
          const validQuestionnaires = data.filter(
            (q) => q.questions.length && q.playbookIds.length
          );

          const filteredContractTypes = contractTypes.filter((contractType) =>
            validQuestionnaires.some(
              (questionnaire) => questionnaire.contractType === contractType.name
            )
          );

          setContractTypes(filteredContractTypes);
          if (filteredContractTypes.length === 1) {
            getQuestionnairesByContractType(filteredContractTypes[0].name);
            setValue('contractType', filteredContractTypes[0].name);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  async function loadIntegration() {
    const headers = await getAuthHeader();
    const { data: integration } = await integrationsRequests.getIntegration(
      headers,
      'lawvu'
    );

    if (integration?.templates) {
      setTemplateTypes(integration?.templates);
      setFilteredTemplateTypes(integration?.templates);
    }

    setIsLoadingTemplates(false);
  }

  const getQuestionnairesByContractType = (type) => {
    getAuthHeader().then((headers) => {
      GetQuestionnairesByContractType(headers, type)
        .then((data) => {
          // If there is one default questionnaire, set setValue
          if (data.length === 1) {
            setValue('defaultQuestionnaire', data[0]._id);
          }
          const validQuestionnaires = data.filter(
            (q) => q.questions.length && q.playbookIds.length
          );
          setDefaultQuestionnaires(validQuestionnaires);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchSrcPlaybook = async (questionnaireId, headers) => {
    try {
      if (!headers) {
        headers = await getAuthHeader();
      }
      const { data: srcPb } = await axios.get(MODELS_BY_QUESTIONNAIRE, {
        headers,
        params: { questionnaireId },
      });
      return srcPb;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const updateCompanySettings = (data) => {
    getAuthHeader().then((headers) => {
      axios
        .post(
          `${SETTINGS_ENDPOINT}`,
          { companyData: data, updatedFieldNames: [] },
          { headers }
        )
        .then(({ data }) => {
          setConfig(data);
        })
        .catch(() => {
          dispatch(setErrMsg('Failed to save changes!'));
        });
    });
  };

  const handleSetActiveStep = (currStep) => {
    setActiveStep(currStep);
  };

  const handleShowResult = () => {
    updateCompanySettings({
      ...config,
      showOnboarding: false,
      showOnboardingIntegration: false,
    });
    setShowThankYou(true);
  };

  const handleCreate = (data) => {
    if (!getValues('name').length && activeStep === 0) {
      setError('name', { message: 'Playbook name cannot be blank' });
      return;
    }
    if (!getValues('contractType') && activeStep === 0) {
      setError('contractType', { message: 'A contract type must be selected' });
      return;
    }
    if (
      !getValues('templateType') &&
      !!filteredTemplateTypes.length &&
      activeStep === 0
    ) {
      setError('templateType', { message: 'A template type must be selected' });
      return;
    }
    if (!defaultQuestionnaires.length && activeStep === 0) {
      setError('contractType', {
        message:
          'Contract type does not have questionnaire, please select a different contract type',
      });
      return;
    }
    if (!getValues('defaultQuestionnaire') && activeStep === 0) {
      setError('defaultQuestionnaire', {
        message: 'A questionnaire must be selected',
      });
      return;
    }

    setIsCreateLoading(true);

    getAuthHeader().then(async (headers) => {
      const { name, contractType, templateType, defaultQuestionnaire } = data;
      // Fetch the base playbook so that the new playbook can inherit properties
      const srcPlaybook = await fetchSrcPlaybook(defaultQuestionnaire, headers);
      if (!srcPlaybook) {
        console.warn(
          `Cannot fetch model questionnaire ${defaultQuestionnaire}. ` +
            `Model will not inherit properties from base model.`
        );
      }

      const modelData = {
        name,
        isDeleteProtected: false,
        stage: 'published',
        contractType,
        templateType,
        defaultQuestionnaire,
        groups: [],
        generateAddendum: false,
        addendumTemplate: null,
        emailOnUpload: false,
        convertPdfToDocx: false,
        onboarding: true,
        similarityModel: srcPlaybook?.similarityModel,
      };

      Promise.resolve(dispatch(CreateModelFromInputs(headers, modelData)))
        .then(
          ({ modelId: newModelId, isChangedPreferredModel: preferredModelChanged }) => {
            setModelId(newModelId);
            setIsChangedPreferredModel(preferredModelChanged);
            handleSetActiveStep(1);
          }
        )
        .catch((err) => {
          console.log('Error! Failed to create q&a model with error:', err);
          setErr('Error! Failed to create q&a model');
        })
        .finally(() => {
          setIsCreateLoading(false);
        });
    });
  };

  useEffect(() => {
    loadIntegration();
  }, []);

  useEffect(() => {
    let fetchTimer = null;
    if (!templateTypes.length && !isLoadingTemplates) {
      fetchTimer = setTimeout(() => {
        loadIntegration();
      }, 5000);
    }

    return () => {
      if (fetchTimer) {
        clearTimeout(fetchTimer);
      }
    };
  }, [templateTypes, isLoadingTemplates]);

  useEffect(() => {
    setModelId(createdModelId);
    setShowThankYou(false);
  }, [createdModelId]);

  useEffect(() => {
    if (watch().contractType) {
      // 1 - clean contract type error
      clearErrors(['contractType']);
      // 2 - reset default questionnaire field
      resetField('defaultQuestionnaire');
      getQuestionnairesByContractType(watch().contractType);
      // 3 - filter the template types
      const filteredTemplates = templateTypes.filter(
        (templateType) =>
          templateType.type ===
          contractTypes.find((contractType) => contractType.name === watch().contractType)
            ?.label
      );
      if (Boolean(filteredTemplates.length)) {
        setFilteredTemplateTypes(filteredTemplates);
      } else {
        setFilteredTemplateTypes(templateTypes);
      }
    }
    if (watch().defaultQuestionnaire) {
      clearErrors(['defaultQuestionnaire']);
    }
  }, [watch().contractType]);

  useEffect(() => {
    if (watch().name.length) {
      clearErrors(['name']);
    }
  }, [watch().name]);

  useEffect(() => {
    if (watch().templateType) {
      clearErrors(['templateType']);
    }
  }, [watch().templateType]);

  useEffect(() => {
    if (watch().defaultQuestionnaire) {
      clearErrors(['defaultQuestionnaire']);
    }
  }, [watch().defaultQuestionnaire]);

  useEffect(() => {
    // set company name
    const name = config?.displayName || config?.name;
    if (name) setValue('name', capitalizeFirstLetter(name));

    if (modelId && activeStep < 1) {
      setActiveStep(1); // if model move forward but not back
    } else {
      // set playbook name with company name
      if (name) setValue('name', capitalizeFirstLetter(name));

      if (config && config.contractTypes) {
        getQuestionnaires(config.contractTypes);
      }
    }
  }, [config]);

  return (
    <Flex.V
      sx={{
        gap: '32px',
        '.MuiSvgIcon-root': { fontSize: '2rem !important' },
        '.MuiStepIcon-text': { fontSize: '1.25rem !important', fontWeight: 700 },
      }}
    >
      <div ref={topRef} />
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPPER_STEPS.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <OnboardingStepContent
        step={activeStep}
        templateTypes={filteredTemplateTypes}
        handleCreate={handleSubmit(handleCreate)}
        isCreateLoading={isCreateLoading}
        topRef={topRef}
        {...{
          getValues,
          setValue,
          errors,
          defaultQuestionnaires,
          modelId,
          contractTypes,
          handleSetActiveStep,
          handleShowResult,
          isCreateLoading,
        }}
      />
    </Flex.V>
  );
};

OnboardingAnswerQuestions.propTypes = {
  createdModelId: PropTypes.string,
  setIsChangedPreferredModel: PropTypes.func,
  setShowThankYou: PropTypes.func,
};

export default OnboardingAnswerQuestions;
