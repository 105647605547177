import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';

import BlackBoilerLogo from '../../assets/images/bb_logo_oneline.png';
import { SETTINGS_ENDPOINT } from '../../endpoints.config';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { getSubdomain } from '../../utils/OnboardingUtils';
import { getRedirect } from '../../auth/AuthUtils';
import * as integrationsRequests from '../../requests/integrations';
import { Flex, BaseDialogPage } from '../../components';
import OnboardingThankYou from './OnboardingThankYou';
import OnboardingAnswerQuestions from './OnboardingAnswerQuestions';
import OnboardingIntegration from './OnboardingIntegration';

const DialogTitle = ({ children, onClose, ...other }) => {
  return (
    <MuiDialogTitle sx={{ padding: '20px' }} {...other}>
      <Flex.H sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ width: '40px' }} />
        <Typography variant="h6" sx={{ width: '100%' }}>
          {children}
        </Typography>
        {onClose && (
          <Box>
            <IconButton aria-label="close" onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Flex.H>
    </MuiDialogTitle>
  );
};

const OnboardingDialog = ({ open, handleClose, createdModelId }) => {
  const [mainActiveStep, setMainActiveStep] = useState(0);
  const [isChangedPreferredModel, setIsChangedPreferredModel] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const subdomain = getSubdomain();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { config, setConfig, getAuthHeader } = useAuthUpdateContext();
  const { showOnboarding, showOnboardingIntegration } = config || {};

  const setErr = (value) => dispatch(setErrMsg(value));

  const stepperSteps = useMemo(() => {
    const steps = [];

    if (showOnboardingIntegration && !showOnboarding) {
      steps.push('SETUP INTEGRATION');
      setMainActiveStep(0);
    } else if (showOnboardingIntegration && showOnboarding) {
      steps.push('WELCOME', 'STEP 1: SETUP INTEGRATION', 'STEP 2: SETUP PLAYBOOK');
    } else {
      steps.push('SETUP PLAYBOOK');
      setMainActiveStep(0);
    }

    return steps;
  }, [showOnboarding, showOnboardingIntegration]);

  const updateCompanySettings = (data) => {
    getAuthHeader().then((headers) => {
      axios
        .post(
          `${SETTINGS_ENDPOINT}`,
          { companyData: data, updatedFieldNames: [] },
          { headers }
        )
        .then(({ data }) => {
          setConfig(data);
        })
        .catch(() => {
          console.log('Failed to save changes!');
        });
    });
  };

  async function handleRegisterIntegration(queryMap) {
    const headers = await getAuthHeader();
    try {
      console.debug(`Attempting to register integration...`);
      const { data: registerResp } = await integrationsRequests.registerIntegration(
        headers,
        queryMap
      );
      console.debug(`Register integration responded with:`);
      console.debug(registerResp);

      // Update settings
      updateCompanySettings({ ...config, showOnboardingIntegration: false });

      if (!config.showOnboarding) {
        setShowThankYou(true);
      } else if (config.showOnboardingIntegration) {
        setMainActiveStep(2);
      } else {
        setMainActiveStep(0);
      }
    } catch (err) {
      console.error(err);
      setErr('Failed to authorize user via code flow');
    }
  }

  function checkForCodeFlow() {
    const { queryMap } = getRedirect(search);

    if (queryMap.state && queryMap.icode) {
      handleRegisterIntegration(queryMap);
    }
  }

  useEffect(() => {
    if (config) {
      checkForCodeFlow();
    }
  }, [config]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      disableEscapeKeyDown
      open={open}
      maxWidth={showThankYou ? 'xs' : 'lg'}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose(event);
      }}
    >
      {showThankYou ? (
        <OnboardingThankYou {...{ handleClose, isChangedPreferredModel, subdomain }} />
      ) : (
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Flex.V
              sx={{
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={BlackBoilerLogo}
                style={{ width: '300px' }}
                alt="BlackBoiler Company Logo"
              />
              <Typography variant="h1">{stepperSteps[mainActiveStep]}</Typography>
            </Flex.V>
          </DialogTitle>

          <DialogContent dividers sx={{ padding: '32px' }}>
            {mainActiveStep === 0 && showOnboarding && showOnboardingIntegration && (
              <BaseDialogPage
                title="Setup Guide"
                extendedTitle="Let's complete the following steps before getting started"
                rButtonText="Start"
                rButtonOnClick={() => setMainActiveStep(1)}
                showLButton={false}
                showTopNav={false}
              >
                <Flex.V sx={{ gap: '12px' }}>
                  <Typography variant="h2">STEP 1: SETUP INTEGRATION</Typography>
                  <Typography variant="h2">STEP 2: SETUP PLAYBOOK</Typography>
                </Flex.V>
              </BaseDialogPage>
            )}

            {(mainActiveStep === 1 || !showOnboarding) && <OnboardingIntegration />}

            {(mainActiveStep === 2 || !showOnboardingIntegration) && (
              <OnboardingAnswerQuestions
                {...{
                  createdModelId,
                  setIsChangedPreferredModel,
                  setShowThankYou,
                }}
              />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

OnboardingDialog.propTypes = {
  createdModelId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OnboardingDialog;
